import _isLittleEndian from "is-little-endian";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = noisify;

function rand() {
  return Math.random() * 255 & 255;
}

var pixel = _isLittleEndian ? function le(r, g, b) {
  return 255 << 24 | b << 16 | g << 8 | r;
} : function be(r, g, b) {
  return r << 24 | g << 16 | b << 8 | 255;
};

function generate(ctx) {
  var _ref = arguments[1] === undefined ? {} : arguments[1];

  var _ref$mode = _ref.mode;
  var mode = _ref$mode === undefined ? "grey" : _ref$mode;
  var w = ctx.canvas.width,
      h = ctx.canvas.height,
      imgData = ctx.createImageData(w, h),
      buf = new ArrayBuffer(imgData.data.length),
      pix = new Uint8ClampedArray(buf),
      data = new Uint32Array(buf);

  for (var i = 0, l = data.length; i < l; ++i) {
    var r = undefined,
        g = undefined,
        b = undefined;

    if (mode === "rgb") {
      r = rand();
      g = rand();
      b = rand();
    } else {
      r = g = b = rand();
    }

    data[i] = pixel(r, g, b);
  }

  imgData.data.set(pix);
  return imgData;
}

function noisify(canvas, opts) {
  var reqId = undefined;

  function step(ctx) {
    ctx.putImageData(generate(ctx, opts), 0, 0);
    reqId = window.requestAnimationFrame(function () {
      return step(ctx);
    });
  }

  return {
    canvas: canvas,
    start: function start() {
      step(canvas.getContext("2d"));
    },
    stop: function stop() {
      window.cancelAnimationFrame(reqId);
    }
  };
}

exports = exports["default"];
export default exports;
export const __esModule = exports.__esModule;